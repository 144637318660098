import React from "react";
import BackgroundImage from 'gatsby-background-image'


const Section = ({bg, children, classes, clickEvent}) => {

	return (
		<>
			{bg.type === `color` 
				? <section className={classes} style={{background: bg.color}}>
					{children}
				</section>
				: <section className={classes} style={{background: bg.bgColor}} onClick={() => clickEvent()}>
					<BackgroundImage Tag="div" fluid={bg.image.childImageSharp.fluid} className="speaker-bg" durationFadeIn={200}>
						{children}
					</BackgroundImage>
				</section>

				
			}
		</>
	);
};

export default Section;